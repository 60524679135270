import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  gap: 1rem;
  padding: 2.5rem;
  margin-left: 25%;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colorTextNeutralDefault};
  margin-bottom: 0.25rem;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: start;
`
