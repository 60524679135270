import { createContext, useState } from 'react'

interface AIProviderProps {
  children: React.ReactNode
}

interface AIProvider {
  conversation: Conversation[]
  addPhrase: (phrase: Conversation) => void
}

interface Conversation {
  message: string
  role: 'sender' | 'receiver'
}

export const AIContext = createContext({} as AIProvider)

export default function AIProvider({ children }: AIProviderProps) {
  const [conversation, setConversation] = useState<Conversation[]>([])

  function addPhrase(phrase: Conversation) {
    setConversation((prev) => [...prev, phrase])
  }

  const value = {
    conversation,
    addPhrase
  }

  return <AIContext.Provider {...{ value }}>{children}</AIContext.Provider>
}
