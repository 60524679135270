import { Link as RouterLink } from 'react-router-dom'
import { css, styled } from 'styled-components'
import { LogoRaiaDrogasil } from '@raiadrogasil/pulso-react-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`

export const Logo = styled(LogoRaiaDrogasil)``

export const Content = styled.div`
  display: flex;
  flex: 0.7;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  gap: 1rem;
`

export const Form = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  gap: 1rem;
  padding: 2rem;
  border-radius: 8px;
  width: 600px;
  max-width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`

export const Input = styled.input<{
  $isError: boolean
}>`
  width: 100%;
  padding: 0.75rem;
  color: ${({ theme }) => theme.colorTextNeutralDefault};
  font-size: 0.9rem;
  border-radius: 4px;
  border: 2px solid
    ${({ theme }) => theme.colorActionBorderNeutralSecondaryEnabled};
  transition: 0.2s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  }

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${({ theme }) => theme.colorFormsBorderError};
    `}
`

export const TextArea = styled.textarea<{
  $isError: boolean
}>`
  outline: none;
  appearance: none;
  height: 140px;
  background-color: transparent;
  font-size: 0.9rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 2px solid
    ${({ theme }) => theme.colorActionBorderNeutralSecondaryEnabled};
  transition: 0.2s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  }

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${({ theme }) => theme.colorFormsBorderError};
    `}
`

export const Label = styled.p`
  font-size: 1.25rem;
  margin-top: -16px;
  color: ${({ theme }) => theme.colorTextNeutralDefault};
`

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colorFormsBorderError};
`

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  transition: 0.2s ease-in-out;

  color: ${({ theme }) => theme.colorTextNeutralInverse};
  font-size: 0.9rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      theme.colorActionFillBrandPrimaryHovered};
  }
`

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.colorTextNeutralDefault};
  transition: 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colorActionFillBrandPrimaryHovered};
  }
`

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;

  &:after {
    content: '';
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${({ theme }) => theme.colorTextNeutralDefault};
    display: inline-block;
    position: absolute;
    top: calc(50% - 3px);
    right: 0.75rem;
  }
`

export const Select = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 2px solid
    ${({ theme }) => theme.colorActionBorderNeutralSecondaryEnabled};
  appearance: none;
  transition: 0.2s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  }
`

export const Option = styled.option``
