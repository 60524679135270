import { Navigate } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

/* eslint-disable react/display-name */
export function ProtectedRoute<T extends object>(
  Component: React.FunctionComponent<T>
) {
  function NewComponent(props: T) {
    const { isAuthenticated } = useAuth()

    if (!isAuthenticated) {
      return <Navigate to="/login" replace />
    }

    return <Component {...props} />
  }

  return (props: T) => <NewComponent {...props} />
}
// eslint-disable-next-line react/display-name
