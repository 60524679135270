import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
`

export const Overlapping = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

export const Content = styled.div`
  padding: 16px;
  font-size: 0.9rem;

  p:first-of-type,
  p:nth-of-type(even) {
    font-size: 1.1rem;
    font-weight: bold;
  }

  p:nth-of-type(even) {
    font-size: 1rem;
    margin-top: 0.75rem;
  }
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 0.75rem 0;
  background-color: ${({ theme }) => theme.colorBorderNeutralAlternative};
`

export const Button = styled.button<{
  $secondary?: boolean
}>`
  cursor: pointer;
  padding: 0.65rem 2rem;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  transition: 0.2s ease-in-out;
  color: ${({ theme }) => theme.colorTextNeutralInverse};
  font-size: 0.9rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      theme.colorActionFillBrandPrimaryHovered};
  }

  ${({ $secondary }) =>
    $secondary &&
    css`
      background-color: rgba(182, 32, 47, 1);
      &:hover,
      &:focus {
        background-color: rgba(148, 25, 37, 1);
      }
    `}
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`
