import { useAuth } from '../../hooks/useAuth'
import { useSearch } from '../../store'
import {
  Container,
  Form,
  Input,
  Button,
  Logo,
  SelectContainer,
  Select
} from './styles'
import * as enums from '../../utils/enums/Task'

const categories = [...Object.values(enums.Category), 'Todos']

const allStatus = [...Object.values(enums.Status), 'Todos']

function SideBar() {
  const { term, category, status, setTerm, setStatus, setCategory } =
    useSearch()

  const { signOut } = useAuth()

  return (
    <Container>
      <Logo />

      <Form>
        <Input
          type="text"
          placeholder="Buscar"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        />

        <SelectContainer>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            {allStatus.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </SelectContainer>

        <SelectContainer>
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </SelectContainer>
      </Form>

      <Button onClick={signOut}>Sair</Button>
    </Container>
  )
}

export default SideBar
