import { z } from 'zod'

const minUser = 4,
  maxUser = 30

export const forgotPasswordSchema = z.object({
  username: z
    .string({ required_error: 'O usuário é obrigatório' })
    .min(minUser, `O usuário deve ter ao menos ${minUser} dígitos`)
    .max(maxUser, `O usuário deve ter no máximo ${maxUser} dígitos`)
    .trim()
})

export type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>
