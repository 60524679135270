import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
`

export const ButtonAdd = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  font-size: 40px;
  border-radius: 50%;
  text-decoration: none;
  color: ${({ theme }) => theme.colorTextNeutralInverse};
  background-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
`
