import { useState } from 'react'

export function useConversation() {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function mutate(question: string): Promise<string> {
    try {
      setIsLoading(true)

      const url = new URL(
        '/conversations/',
        process.env.REACT_APP_AICHAT_URL || 'http://localhost:9002'
      )

      url.searchParams.append('question', question)

      const response = await fetch(url.toString(), {
        method: 'GET'
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      return data.response
    } catch (error) {
      setError(error as string)

      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return {
    error,
    isLoading,
    mutate
  }
}
