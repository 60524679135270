import { z } from 'zod'

const minUser = 4,
  maxUser = 30,
  minPassword = 4,
  maxPassword = 30

export const loginSchema = z.object({
  username: z
    .string({ required_error: 'O usuário é obrigatório' })
    .min(minUser, `O usuário deve ter ao menos ${minUser} dígitos`)
    .max(maxUser, `O usuário deve ter no máximo ${maxUser} dígitos`)
    .trim(),
  password: z
    .string({ required_error: 'A senha é obrigatória' })
    .min(minPassword, `A senha deve ter ao menos ${minPassword} dígitos`)
    .max(maxPassword, `A senha deve ter no máximo ${maxPassword} dígitos`)
    .trim()
})

export type LoginSchema = z.infer<typeof loginSchema>
