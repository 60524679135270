import { z } from 'zod'
import { loginSchema } from '../Login/schema'

export const roleOptions = [
  { value: 'idealizer', label: 'Idealizador' },
  { value: 'admin', label: 'Administrador' }
]

export const registerSchema = z.intersection(
  loginSchema,
  z.object({ role: z.enum(['idealizer', 'admin']) })
)

export type RegisterSchema = z.infer<typeof registerSchema>
