import { create } from 'zustand'
import * as enums from '../../utils/enums/Task'
import { httpClient } from '../../infra/httpClient'
import { getJwtDecoded } from '../../providers/AuthProvider'

export interface Ideas {
  _id: string
  name: string
  registration: string
  title: string
  description: string
  classification: string[]
  status: string
  userId: {
    _id: string
    username: string
  }
}

interface Search {
  ideas: Ideas[]
  term: string
  category: string
  status: string
  getFilteredIdeas: () => Ideas[]
  setTerm: (term: string) => void
  setStatus: (status: string) => void
  setCategory: (category: string) => void
  setIdeas: (ideas: Ideas[]) => void
  fetchIdeas: () => Promise<void>
  removeIdea: (id: string) => Promise<void>
  updateIdea: (id: string, data: Partial<Ideas>) => Promise<void>
  reset: () => void
}

const initialState = {
  ideas: [],
  term: '',
  category: enums.Category.AUMENTO,
  status: enums.Status.AGUARDANDO_AVALIACAO
}

export const useSearch = create<Search>((set, get) => ({
  ...initialState,
  fetchIdeas: async () => {
    const response = await httpClient.get(`ideas`)

    const { isAdmin, id } = getJwtDecoded()

    const filteredIdeas = response.data.filter((idea: Ideas) => {
      if (isAdmin) return true

      return idea.userId._id === id
    })

    set({ ideas: filteredIdeas })
  },
  getFilteredIdeas: () => {
    const filteredIdeasByTerm = get().ideas.filter((idea) =>
      idea.title.toLowerCase().includes(get().term.toLowerCase())
    )

    const filteredIdeasByCategory =
      get().category === 'Todos'
        ? filteredIdeasByTerm
        : filteredIdeasByTerm.filter(
            (idea) => idea.classification[0] === get().category
          )

    const filteredIdeasByStatus =
      get().status === 'Todos'
        ? filteredIdeasByCategory
        : filteredIdeasByCategory.filter(
            (idea) => idea.status.toLowerCase() === get().status.toLowerCase()
          )

    return filteredIdeasByStatus
  },
  setTerm: (term: string) => set({ term }),
  setCategory: (category: string) => set({ category }),
  setStatus: (status: string) => set({ status }),
  setIdeas: (ideas: Ideas[]) => set({ ideas }),
  updateIdea: async (id: string, data: Partial<Ideas>) => {
    await httpClient.patch(`ideas/${id}`, data)

    await get().fetchIdeas()
  },
  removeIdea: async (id: string) => {
    await httpClient.delete(`ideas/${id}`)

    await get().fetchIdeas()
  },
  reset: () => set({ ...initialState })
}))
