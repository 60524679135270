import { SideBar, TaskList } from '../../../components'
import { ProtectedRoute } from '../../../hocs/protectedRoute'
import { ButtonAdd, Container } from './styles'

const Home = () => {
  return (
    <Container>
      <SideBar />

      <TaskList />

      <ButtonAdd to={'/new-idea'}>+</ButtonAdd>
    </Container>
  )
}

export default ProtectedRoute(Home)
