import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Icon } from '@raiadrogasil/pulso-react-components'

import {
  Container,
  Form,
  Button,
  Input,
  Logo,
  ErrorMessage,
  Field,
  Row
} from '../styles'
import { BackButton } from './styles'
import { ForgotPasswordSchema, forgotPasswordSchema } from './schema'
import { httpClient } from '../../../infra/httpClient'

const ForgotPassword = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordSchema>({
    resolver: zodResolver(forgotPasswordSchema)
  })

  function handleBack() {
    navigate('/login')
  }

  async function onSubmit(data: ForgotPasswordSchema) {
    try {
      await httpClient.post('http://localhost:5001/forgot-password', {
        username: data.username
      })

      navigate('/login')
    } catch (err) {
      console.error(`Registration failed: ${err}`)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <BackButton onClick={handleBack}>
            <Icon size="medium" symbol="rdicon-arrow-left" />
          </BackButton>

          <Logo />
        </Row>

        <Field>
          <Input
            {...register('username')}
            type="text"
            placeholder="Usuário"
            $isError={!!errors.username?.message}
          />

          {errors.username && (
            <ErrorMessage>{errors.username?.message}</ErrorMessage>
          )}
        </Field>

        <Button style={{ marginTop: '0.25rem' }} type="submit">
          Registrar
        </Button>
      </Form>
    </Container>
  )
}

export default ForgotPassword
