import { z } from 'zod'

const minName = 4,
  maxName = 30,
  minRegistration = 4,
  maxRegistration = 30,
  minTitle = 4,
  maxTitle = 30,
  minDescription = 20,
  maxDescription = 8000

export const classificationOptions = [
  'Aumento de Vendas',
  'Redução de Custos ou Aumento de Produtividade',
  'Sustentabilidade, Diversidade e Inclusão',
  'Experiência do Funcionário e Marca Empregador',
  'Experiência do Cliente (Digital ou Farmácia)',
  'Visibilidade da Marca RD Saúde'
]

export const newIdeaSchema = z.object({
  name: z
    .string({ required_error: 'O nome é obrigatório' })
    .min(minName, `O nome deve ter ao menos ${minName} dígitos`)
    .max(maxName, `O nome deve ter no máximo ${maxName} dígitos`)
    .trim(),
  registration: z
    .string({ required_error: 'A matrícula é obrigatório' })
    .min(
      minRegistration,
      `A matrícula deve ter ao menos ${minRegistration} dígitos`
    )
    .max(
      maxRegistration,
      `A matrícula deve ter no máximo ${maxRegistration} dígitos`
    )
    .trim(),
  title: z
    .string({ required_error: 'O título é obrigatório' })
    .min(minTitle, `O título deve ter ao menos ${minTitle} dígitos`)
    .max(maxTitle, `O título deve ter no máximo ${maxTitle} dígitos`)
    .trim(),
  description: z
    .string({ required_error: 'A descrição é obrigatória' })
    .min(
      minDescription,
      `A descrição deve ter ao menos ${minDescription} dígitos`
    )
    .max(
      maxDescription,
      `A descrição deve ter no máximo ${maxDescription} dígitos`
    )
    .trim(),
  classification: z.enum([
    'Aumento de Vendas',
    'Redução de Custos ou Aumento de Produtividade',
    'Sustentabilidade, Diversidade e Inclusão',
    'Experiência do Funcionário e Marca Empregador',
    'Experiência do Cliente (Digital ou Farmácia)',
    'Visibilidade da Marca RD Saúde'
  ])
})

export type NewIdeaSchema = z.infer<typeof newIdeaSchema>
