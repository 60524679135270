import { useState } from 'react'
import { Ideas, useSearch } from '../../store'
import {
  Button,
  ButtonGroup,
  Container,
  Content,
  Overlapping,
  Separator
} from './styles'
import { useAuth } from '../../hooks/useAuth'
import * as enums from '../../utils/enums/Task'
import Loading from '../Loading'

function Task(idea: Ideas) {
  const { isAdmin } = useAuth()

  const { updateIdea, removeIdea } = useSearch((state) => ({
    updateIdea: state.updateIdea,
    removeIdea: state.removeIdea
  }))

  //   const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  //   const [isDisabled, setIsDisabled] = useState(true)
  //   const [description, setDescription] = useState(idea.description)

  async function remove(id: string) {
    setIsLoading(true)

    await removeIdea(id)

    setIsLoading(false)
  }

  async function approve(id: string) {
    setIsLoading(true)

    await updateIdea(id, { status: enums.Status.APROVADO })

    setIsLoading(false)
  }

  async function disapprove(id: string) {
    setIsLoading(true)

    await updateIdea(id, { status: enums.Status.REPROVADO })

    setIsLoading(false)
  }

  return (
    <Container>
      {isLoading && (
        <Overlapping>
          <Loading />
        </Overlapping>
      )}

      <Content>
        <p>
          {idea.name} ({idea.registration})
        </p>

        <p>Título</p>
        <p>{idea.title}</p>

        <p>Classificação</p>
        <p>{idea.classification}</p>

        <p>Status</p>
        <p>{idea.status}</p>

        <p>Descricão</p>
        <p>{idea.description}</p>

        <Separator />

        <ButtonGroup>
          {(idea.status !== enums.Status.AGUARDANDO_AVALIACAO || !isAdmin) && (
            <Button $secondary onClick={() => remove(idea._id)}>
              Remover
            </Button>
          )}

          {isAdmin && idea.status === enums.Status.AGUARDANDO_AVALIACAO && (
            <Button $secondary onClick={() => disapprove(idea._id)}>
              Reprovar
            </Button>
          )}

          {isAdmin && idea.status === enums.Status.AGUARDANDO_AVALIACAO && (
            <Button onClick={() => approve(idea._id)}>Aprovar</Button>
          )}
        </ButtonGroup>
      </Content>
    </Container>
  )
}

export default Task
