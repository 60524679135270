import { LogoRaiaDrogasilWhite } from '@raiadrogasil/pulso-react-components'
import { styled } from 'styled-components'

export const Container = styled.aside`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  height: 100vh;
  display: flex;
  position: fixed;
  width: 25%;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`

export const Logo = styled(LogoRaiaDrogasilWhite)``

export const Form = styled.form`
  gap: 0.75rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  color: ${({ theme }) => theme.colorTextNeutralDefault};
  font-size: 0.9rem;
  border-radius: 4px;
  border: 2px solid
    ${({ theme }) => theme.colorActionBorderNeutralSecondaryEnabled};
  transition: 0.2s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  }
`

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;

  &:after {
    content: '';
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${({ theme }) => theme.colorTextNeutralDefault};
    display: inline-block;
    position: absolute;
    top: calc(50% - 3px);
    right: 0.75rem;
  }
`

export const Select = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 2px solid
    ${({ theme }) => theme.colorActionBorderNeutralSecondaryEnabled};
  appearance: none;
  transition: 0.2s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colorActionFillBrandPrimaryEnabled};
  }
`

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorTextNeutralInverse};
  transition: 0.2s ease-in-out;

  color: ${({ theme }) => theme.colorTextNeutralDefault};
  font-size: 0.9rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      theme.colorActionFillNeutralSecondaryPressed};
  }
`

export const Filters = styled.select`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 16px;
  width: 100%;
`

export const SearchBar = styled.input`
  display: flex;
  background-color: #333;
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
`
