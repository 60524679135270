import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Icon } from '@raiadrogasil/pulso-react-components'

import {
  Container,
  Form,
  Button,
  Input,
  Logo,
  ErrorMessage,
  Field,
  Select,
  Option,
  SelectContainer,
  Row
} from '../styles'
import { BackButton } from './styles'
import { RegisterSchema, registerSchema, roleOptions } from './schema'
import { httpClient } from '../../../infra/httpClient'

const Register = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterSchema>({
    resolver: zodResolver(registerSchema)
  })

  function handleBack() {
    navigate('/login')
  }

  async function onSubmit(data: RegisterSchema) {
    try {
      await httpClient.post('register', {
        username: data.username,
        password: data.password,
        role: data.role
      })

      navigate('/login')
    } catch (err) {
      console.error(`Registration failed: ${err}`)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <BackButton onClick={handleBack}>
            <Icon size="medium" symbol="rdicon-arrow-left" />
          </BackButton>

          <Logo />
        </Row>

        <Field>
          <Input
            {...register('username')}
            type="text"
            placeholder="Usuário"
            $isError={!!errors.username?.message}
          />

          {errors.username && (
            <ErrorMessage>{errors.username?.message}</ErrorMessage>
          )}
        </Field>

        <Field>
          <Input
            {...register('password')}
            type="password"
            placeholder="Senha"
            $isError={!!errors.password?.message}
          />

          {errors.password && (
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          )}
        </Field>

        <SelectContainer>
          <Select {...register('role')}>
            {roleOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </SelectContainer>

        <Button style={{ marginTop: '0.25rem' }} type="submit">
          Registrar
        </Button>
      </Form>
    </Container>
  )
}

export default Register
