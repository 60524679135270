import { useEffect, useState } from 'react'
import { useSearch } from '../../store'
import { Container, List, Title } from './styles'
import Loading from '../Loading'
import Task from '../Task'

function TaskList() {
  const { getFilteredIdeas, fetchIdeas } = useSearch()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchIdeas()
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Title>{getFilteredIdeas().length} ideia(s) encontrada(s)</Title>

          <List>
            {getFilteredIdeas().map((idea) => (
              <Task key={idea._id} {...idea} />
            ))}
          </List>
        </>
      )}
    </Container>
  )
}

export default TaskList
