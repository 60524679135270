import styled from 'styled-components'

export const BackButton = styled.button`
  display: flex;
  position: absolute;
  left: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`
