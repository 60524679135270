export enum Status {
  AGUARDANDO_AVALIACAO = 'Aguardando Avaliação',
  // EM_ANALISE = 'Em análise',
  REPROVADO = 'Reprovado',
  APROVADO = 'Aprovado para MVP'
}

export enum Category {
  AUMENTO = 'Aumento de Vendas',
  REDUCAO = 'Redução de Custos ou Aumento de Produtividade',
  SUSTENTABILIDADE = 'Sustentabilidade, Diversidade e Inclusão',
  EXPERIENCIA = 'Experiência do Funcionário e Marca Empregador',
  CLIENTE = 'Experiência do Cliente (Digital ou Farmácia)',
  VISIBILIDADE = 'Visibilidade da Marca RD Saúde'
}
