import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  Container,
  Form,
  Button,
  Input,
  Logo,
  ErrorMessage,
  Field,
  Link,
  Label
} from '../styles'
import { useAuth } from '../../../hooks/useAuth'
import { LoginSchema, loginSchema } from './schema'
import { ForgotPassword } from './styles'
import { httpClient } from '../../../infra/httpClient'

const Login = () => {
  const { signIn } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema)
  })

  async function onSubmit(data: LoginSchema) {
    try {
      const response = await httpClient.post('login', {
        username: data.username,
        password: data.password
      })

      signIn(response.data.token)
    } catch (err) {
      alert(`Login failed: ${err}`)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Logo />

        <Field>
          <Input
            {...register('username')}
            type="text"
            placeholder="Usuário"
            $isError={!!errors.username?.message}
          />

          {errors.username && (
            <ErrorMessage>{errors.username?.message}</ErrorMessage>
          )}
        </Field>

        <Field>
          <Input
            {...register('password')}
            type="password"
            placeholder="Senha"
            $isError={!!errors.password?.message}
          />

          {errors.password && (
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          )}
        </Field>

        <ForgotPassword>
          <Link to="/forgot-password">Esqueceu sua senha?</Link>
        </ForgotPassword>

        <Button type="submit">Faça seu login</Button>

        <Label>
          Não tem uma conta? <Link to="/register">Registre-se</Link>
        </Label>
      </Form>
    </Container>
  )
}

export default Login
