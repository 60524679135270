import { useEffect, useLayoutEffect, useRef } from 'react'
import Markdown from 'react-markdown'

import {
  Chat,
  Container,
  Empty,
  Logo,
  Message,
  Paragraph,
  TextArea,
  TextAreaContainer
} from './styles'
import { useAI } from '../../hooks/useAI'
import { useConversation } from '../../useCases/useConversation'
import { Loading } from '..'

function AIPrompt() {
  const { conversation, addPhrase } = useAI()

  const { isLoading, mutate } = useConversation()

  const listRef = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const initialHeight = '40px'

  useEffect(() => {
    const textArea = textAreaRef.current

    if (textArea) {
      textArea.style.height = initialHeight

      const adjustHeight = () => {
        textArea.style.height = initialHeight
        textArea.style.height = `${textArea.scrollHeight}px`
      }

      textArea.addEventListener('input', adjustHeight)

      return () => {
        textArea.removeEventListener('input', adjustHeight)
      }
    }
  }, [])

  useLayoutEffect(() => {
    const list = listRef.current

    if (list) {
      list.scrollTop = list.scrollHeight
    }
  }, [conversation, isLoading])

  async function onKeyPress(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()

      const textArea = textAreaRef.current

      if (textArea) {
        const question = textArea.value

        textArea.value = ''
        textArea.style.height = initialHeight

        addPhrase({
          message: question,
          role: 'sender'
        })

        const response = await mutate(question)

        addPhrase({
          message: response,
          role: 'receiver'
        })
      } else {
        alert('Faça uma pergunta antes de enviar!')
      }
    }
  }

  return (
    <Container>
      <Chat ref={listRef}>
        {conversation.map((phrase, index) => (
          <Message key={`${phrase.message}-${index}`} role={phrase.role}>
            {phrase.role === 'receiver' ? (
              <Markdown>{phrase.message}</Markdown>
            ) : (
              <Paragraph>{phrase.message}</Paragraph>
            )}
          </Message>
        ))}

        {conversation.length === 0 && !isLoading && (
          <Empty>
            <Logo>Faça uma pergunta</Logo>
          </Empty>
        )}

        {isLoading && <Loading />}
      </Chat>
      <TextAreaContainer>
        <TextArea
          ref={textAreaRef}
          disabled={isLoading}
          onKeyDown={onKeyPress}
          placeholder="Interaja com a IA para melhorar a sua ideia."
        />
      </TextAreaContainer>
    </Container>
  )
}

export default AIPrompt
