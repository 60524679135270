import { ThemeProvider } from 'styled-components'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { RDSAUDESISTEMAS_TOKENS } from '@raiadrogasil/pulso-design-tokens'

import { AuthProvider } from './providers'
import GlobalStyle from './styles'

import Register from './pages/Account/Register'
import Login from './pages/Account/Login'
import ForgotPassword from './pages/Account/ForgotPassword'
import Home from './pages/Idea/Home'
import NewIdea from './pages/Idea/NewIdea'

const routes = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/new-idea', element: <NewIdea /> }
])

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={{ ...RDSAUDESISTEMAS_TOKENS }}>
        <GlobalStyle />
        <RouterProvider router={routes} />
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
