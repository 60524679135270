import axios, { AxiosError, AxiosResponse } from 'axios'

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001/'
})

httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')

  config.headers.Authorization = `Bearer ${token}`

  return config
})

httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError) => {
    const isUnauthorized =
      error.response?.status === 401 || error.response?.status === 403

    const token = localStorage.getItem('token')

    if (token && isUnauthorized) {
      alert('Token expirado, realize o login novamente!')

      localStorage.removeItem('token')

      window.location.href = '/login'

      return httpClient(error.config!)
    }

    return Promise.reject(error)
  }
)
