import { createContext, useLayoutEffect, useState } from 'react'
import { Buffer } from 'buffer'

import { useSearch } from '../../store'

interface AuthProviderProps {
  children: React.ReactNode
}

interface AuthProvider {
  isAuthenticated: boolean
  isAdmin: boolean
  token: string | null
  signIn: (token: string) => void
  signOut: () => void
}

interface TokenDecoded {
  id: string | null
  isAdmin: boolean
}

export const AuthContext = createContext({} as AuthProvider)

export function getJwtDecoded(): TokenDecoded {
  try {
    const token = localStorage.getItem('token')

    const payload = JSON.parse(
      Buffer.from(token!.split('.')[1], 'base64').toString()
    )

    return {
      id: payload.userId,
      isAdmin: payload.role === 'admin'
    }
  } catch (error) {
    return {
      id: null,
      isAdmin: false
    }
  }
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token') ?? null
  )

  const jwt = getJwtDecoded()

  function signIn(token: string) {
    setToken(token)
  }

  function signOut() {
    setToken(null)

    useSearch.getState().reset()
  }

  const value = {
    isAuthenticated: !!token,
    token,
    isAdmin: jwt.isAdmin,
    signIn,
    signOut
  }

  useLayoutEffect(() => {
    const publicRoutes = ['/login', '/register', '/forgot-password']
    const privateRoutes = ['/', '/new-idea']

    if (token) {
      localStorage.setItem('token', token)

      if (!privateRoutes.includes(window.location.pathname)) {
        window.location.href = '/'
      }
    } else {
      localStorage.removeItem('token')

      if (!publicRoutes.includes(window.location.pathname)) {
        window.location.href = '/login'
      }
    }
  }, [token])

  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>
}
