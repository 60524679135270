import styled, { css } from 'styled-components'
import { LogoRaiaDrogasil } from '@raiadrogasil/pulso-react-components'

export const Logo = styled(LogoRaiaDrogasil)``

export const Container = styled.div`
  display: flex;
  flex: 0.3;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
`

export const Chat = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: 1rem;
  gap: 1rem;
  scroll-behavior: smooth;
`

export const Empty = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`

export const TextAreaContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #e1e1e1;
  padding: 0.5rem;
`

export const TextArea = styled.textarea`
  outline: none;
  appearance: none;
  border: none;
  max-height: 13rem;
  background-color: transparent;
  flex: 1;
  font-size: 0.9rem;
  line-height: 1.5rem;
  padding: 0.5rem;
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 0.9rem;
  gap: 0.5rem;

  ${({ role }) =>
    role === 'sender'
      ? css`
          width: 80%;
          align-self: flex-end;

          text-align: right;
        `
      : css`
          width: 100%;
          align-self: flex-start;
        `}
`

export const Paragraph = styled.p``
