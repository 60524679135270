import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'

import * as enums from '../../../utils/enums/Task'
import { AIPrompt } from '../../../components'
import { ProtectedRoute } from '../../../hocs/protectedRoute'
import { AIProvider } from '../../../providers'
import { classificationOptions, newIdeaSchema, NewIdeaSchema } from './schema'
import {
  Button,
  Container,
  Content,
  ErrorMessage,
  Field,
  Form,
  Input,
  Label,
  Logo,
  Select,
  SelectContainer,
  TextArea,
  Option
} from './styles'
import { httpClient } from '../../../infra/httpClient'

const NewIdea = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NewIdeaSchema>({
    resolver: zodResolver(newIdeaSchema)
  })

  async function onSubmit(data: NewIdeaSchema) {
    try {
      await httpClient.post('ideas', {
        name: data.name,
        registration: data.registration,
        title: data.title,
        description: data.description,
        classification: data.classification,
        status: enums.Status.AGUARDANDO_AVALIACAO
      })

      navigate('/')

      alert('Ideia registrada com sucesso!')
    } catch (err) {
      alert(`Registration failed: ${err}`)
    }
  }

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Logo />

          <Label>Plataforma de Ideias</Label>

          <Field>
            <Input
              {...register('name')}
              type="text"
              placeholder="Seu nome"
              $isError={!!errors.name?.message}
            />

            {errors.name && <ErrorMessage>{errors.name?.message}</ErrorMessage>}
          </Field>

          <Field>
            <Input
              {...register('registration')}
              type="text"
              placeholder="Sua matrícula"
              $isError={!!errors.registration?.message}
            />

            {errors.registration && (
              <ErrorMessage>{errors.registration?.message}</ErrorMessage>
            )}
          </Field>

          <Field>
            <Input
              {...register('title')}
              type="text"
              placeholder="Título da ideia"
              $isError={!!errors.title?.message}
            />

            {errors.title && (
              <ErrorMessage>{errors.title?.message}</ErrorMessage>
            )}
          </Field>

          <Field>
            <TextArea
              {...register('description')}
              placeholder="Descricão da ideia"
              $isError={!!errors.description?.message}
            />

            {errors.description && (
              <ErrorMessage>{errors.description?.message}</ErrorMessage>
            )}
          </Field>

          <SelectContainer>
            <Select {...register('classification')}>
              {classificationOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </SelectContainer>

          <Button style={{ marginTop: '0.25rem' }} type="submit">
            Cadastrar
          </Button>
        </Form>
      </Content>

      <AIProvider>
        <AIPrompt />
      </AIProvider>
    </Container>
  )
}

export default ProtectedRoute(NewIdea)
